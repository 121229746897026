import axios from 'axios'

import '../stylesheets/bootstrap5_grid.scss'

import Swiper from 'swiper'
import SwiperCore, { Navigation } from 'swiper/core'
import 'swiper/swiper.scss'
import Typed from 'typed.js'

require('./../entrypoints/booking/new_order_capture')
require('sharer.js')

document.addEventListener('DOMContentLoaded', () => {
  SwiperCore.use([Navigation])
  var swiperConfig = {
    allowTouchMove: true,
    slidesPerView: 2,
    spaceBetween: 10,
    observer: true,
    observeParents: true,
    rebuildOnUpdate: true,
    // Responsive breakpoints
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 2,
        spaceBetween: 15
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 3,
        spaceBetween: 15
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 4,
        spaceBetween: 15
      },
      // when window width is >= 640px
      780: {
        slidesPerView: 5,
        spaceBetween: 15
      },
      // when window width is >= 640px
      960: {
        slidesPerView: 6,
        spaceBetween: 15
      }
    }
  }
  var swiperConfig1 = {
    ...swiperConfig,
    navigation: {
      nextEl: ".top-next1",
      prevEl: ".top-prev1",
    }
  }
  var swiperConfig2 = {
    ...swiperConfig,
    navigation: {
      nextEl: ".top-next2",
      prevEl: ".top-prev2",
    }
  }
  var swiperConfig3 = {
    ...swiperConfig,
    navigation: {
      nextEl: ".top-next3",
      prevEl: ".top-prev3",
    }
  }
  const swiper1 = new Swiper(".swiper1", swiperConfig1)
  const swiper2 = new Swiper(".swiper2", swiperConfig2)
  const swiper3 = new Swiper(".swiper3", swiperConfig3)

  if (document.querySelector(".sm-gallery__avatar-item")) {
    let avatarSize = document.querySelector(".sm-gallery__avatar-item").width
    document.querySelectorAll(".sm-gallery__avatar-item").forEach((avatarDiv) => {
      avatarDiv.style.height = avatarSize + 'px'
    })
  }

  swiper3.update()
  swiper2.update()
  swiper1.update()

  swiper1.on('realIndexChange', function () {
    loadMoreArtistsPage('currentFeaturedArtistsPage', 'featured_artists', swiper1);
  });
})

function loadMoreArtistsPage(page, type, swiper) {
  const currentArtistPage = localStorage.getItem(page);
  if (currentArtistPage !== '0') {
    localStorage.setItem(page, parseInt(currentArtistPage) + 1);
    axios.get(`${window.location.origin}/load_more_artists`, {
      params: {
        type: type,
        page: localStorage.getItem(page)
      }
    })
    .then(response => {
      const artists = response.data.artists;
      if (artists === '') {
        localStorage.setItem(page, 0);
      } else {
        swiper.appendSlide(response.data.artists)
      }
    });
  }
}

addEventListener('DOMContentLoaded', () => {
  var typeWords = document.querySelector("#type_words").value.split(",")
  var typed = new Typed('.hero-search__input', {
    strings: typeWords,
    smartBackspace: true, // Default value
    typeSpeed: 200,
    loop: true,
    attr: 'placeholder',
    fadeOut: true,
    fadeOutClass: 'home-typed-fade-out',
  });
})

addEventListener('DOMContentLoaded', () => {
  localStorage.setItem('currentFeaturedArtistsPage', 1);
  // let country = localStorage.getItem('location_country')
  // window.addEventListener("storage", function (event) {
  //   if (localStorage.getItem('location_country') !== country) {
  //     location.reload();
  //   }
  // })
})

addEventListener('DOMContentLoaded', () => {
  let newBookingBtns = document.getElementsByClassName("get-new-quote")
  let token = localStorage.getItem('token', '')
  if (token === null || token === '') {
  axios.get(`${window.location.origin}/api_tokens/new.json`)
    .then(response => {
      localStorage.setItem('token', response.data.jwt)
    })
  }
  for (var i = 0; i < newBookingBtns.length; i++) {
    newBookingBtns[i].addEventListener('click', function () {
      let headers = {
        headers: {
          Authorization: localStorage.getItem('token', ''),
        }
      }
      axios.post(`${window.location.origin}/v3/event_bookings/clear.json`, headers)
        .then(res => {
          localStorage.setItem('booking_step', 'select_artist_type')
          window.location.href = '/event_bookings/new'
        }).catch(error => {
          localStorage.setItem('booking_step', 'select_artist_type')
          window.location.href = '/event_bookings/new'
        })
    })
  }
  // For Faqs collapsible feature
  document.querySelectorAll('.faqs-question').forEach(header => {
    header.addEventListener('click', function() {
      const faqItem = this.parentElement;
      faqItem.classList.toggle('collapsed');
    });
  });
})
